<template>
 <el-container class="conbox">
    <!-- <el-aside  :width="isCollapse ? '70px' : '200px' "> -->
    <el-aside width="240px" >
      <!-- <div class="toggle-button" @click="toggleCollapse" >|||</div> -->
      <el-menu background-color="#fff"
        text-color="#333"
        active-text-color="#007BFF"
        unique-opened
        :collapse="isCollapse"
        :collapse-transition="false"
        :router="true"
        :default-active="$route.path" 
        class="left_nav">
        <el-submenu index="1">
          <template slot="title">
            <i class="el-icon-tickets" style="color:#333"></i>
            <span>{{td("订单信息")}}</span>
          </template>
          <el-menu-item index="/orderList">{{td("订单列表")}}</el-menu-item>
          <el-menu-item index="/audit">{{td("可审核订单")}}</el-menu-item>
          <el-menu-item index="/notAudit">{{td("不可审核订单")}}</el-menu-item>
          <el-menu-item index="/auditException">{{td("审核异常订单")}}</el-menu-item>
        </el-submenu>

        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-shopping-bag-2" style="color:#333"></i>
            <span>{{td("商品管理")}}</span>
          </template>
        <el-menu-item index="/fristSKU">{{td("SKU管理")}}</el-menu-item>
        <el-menu-item index="/houseQuery">{{td("仓库库存")}}</el-menu-item>
		<el-menu-item index="/storeInventory">{{td("店铺库存")}}</el-menu-item>
        </el-submenu>

        <el-submenu index="3">
          <template slot="title">
            <i class="el-icon-goods" style="color:#333"></i>
            <span>{{td("店铺管理")}}</span>
          </template>
          <el-menu-item index="/shopee">{{td("shopee授权Login")}}</el-menu-item>
          <el-menu-item index="/lazada">{{td("lazada授权Login")}}</el-menu-item>
<!--          <el-menu-item index="/whcBinding">{{td("whc绑定")}}</el-menu-item>-->
        <el-menu-item index="/storeManagement">{{td("店铺列表")}}</el-menu-item>
        <el-menu-item index="/inventoryConf">{{td("盘点调整配置")}}</el-menu-item>
        </el-submenu>



        <el-menu-item index="/warehouseing">
          <template slot="title">
            <i class="el-icon-shopping-bag-1" style="color:#333"></i>
            <span>{{td("入库订单")}}</span>
          </template>
        </el-menu-item>
        <el-menu-item index="/InOrderException">
          <template slot="title" style="color:#333">
            <i class="el-icon-shopping-bag-1"></i>
            <span>{{td("入库异常")}}</span>
          </template>
        </el-menu-item>
    <el-menu-item index="/warehouseList" >
		  <template slot="title" style="color:#333">
        <i class="el-icon-document-copy"></i>
		    <span>{{td("仓库列表")}}</span>
		  </template>
		</el-menu-item>

		<el-menu-item index="/delivery">
		  <template slot="title" style="color:#333">
        <i class="el-icon-document-checked"></i>
		    <span>{{td("分仓调拨")}}</span>
		  </template>
		</el-menu-item>

    <el-menu-item index="/vasOrder">
		  <template slot="title" style="color:#333">
        <i class="el-icon-document-checked"></i>
		    <span>{{td("增值服务单")}}</span>
		  </template>
		</el-menu-item>
      </el-menu>
    </el-aside >
    <el-main style="background-color:#F1F3FF;padding:0 24px">
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: "ERP",
  data() {
    return {
      isCollapse: false,
    }
  },
  // methods: {
  //点击按钮切换菜单的折叠与展开
  //   toggleCollapse() {
  //     this.isCollapse = !this.isCollapse;
  //   },
  // }
};
</script>

<style scoped>
.conbox {
  height: calc(100vh - 70px);
}
.el-aside {
  font-size: 14px;
  background-color:#fff;
  height: 100%;
}

.el-menu {
  border-right: none;
}
.el-menu span {
  font-size: 18px;
}
.toggle-button {
  background-color: #29384B;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}

.el-menu-item.is-active {
  border-right:#007BFF solid 4px !important;
  background-color: #F5FAFF !important;
  color: #007BFF !important;
}
</style>